import './footer.scss'

const Footer = () => {
  return (
    <div className="footer">
      <span>Dang Bakery&Chiffon</span>
      <span>© freelance Dev Admin Dashboard</span>
    </div>
  )
}

export default Footer